var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": ""
    }
  }, [_vm.myGudang ? _c('header', [_c('h5', [_c('strong', [_vm._v("Lokasi Gudang: " + _vm._s(this.myGudang.nama_gudang))])])]) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "2",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Tanggal")])]), _c('p', [_vm._v(_vm._s(_vm.so.tanggal))])]), _c('b-col', {
    attrs: {
      "md": "2",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("No")])]), _c('p', [_vm._v(_vm._s(_vm.so.no))])]), _c('b-col', {
    attrs: {
      "md": "5",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Keterangan")])]), _c('p', [_vm._v(_vm._s(_vm.so.keterangan ? _vm.so.keterangan : "-"))])]), _vm.so.status == 0 ? _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Status")])]), _c('b-badge', {
    attrs: {
      "variant": "light-danger",
      "size": "sm"
    }
  }, [_vm._v("Belum Selesai")])], 1) : _vm._e(), _vm.so.status == 1 ? _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Status")])]), _c('b-badge', {
    attrs: {
      "variant": "light-success",
      "size": "sm"
    }
  }, [_vm._v("Selesai")])], 1) : _vm._e(), _c('b-col', [_c('b-card', {
    staticStyle: {
      "overflow-y": "auto",
      "max-height": "500px"
    }
  }, [_c('b-table', {
    attrs: {
      "small": "",
      "bordered": "",
      "striped": "",
      "hover": "",
      "responsive": "",
      "fields": _vm.fields,
      "items": _vm.penyimpanan_id
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(jumlah)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: item.jumlah,
            callback: function ($$v) {
              _vm.$set(item, "jumlah", $$v);
            },
            expression: "item.jumlah"
          }
        })];
      }
    }, {
      key: "cell(so)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.id_stok_opname) + " ")];
      }
    }, {
      key: "cell(selisih)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.jumlah - item.stok) + " ")];
      }
    }])
  })], 1)], 1)], 1), _c('footer', {
    staticClass: "d-flex justify-content-end"
  }, [_vm.so.status == 0 ? _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.save($event);
      }
    }
  }, [_vm._v("Simpan")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }